export const textSwe =[
    "Vi är alla passagerare och besättning på rymdskeppet jorden. Så har det varit i all tid, men nu är det kris. De livsuppehållande systemen på rymdskeppet är i ett kritiskt dåligt tillstånd. Matjorden, dricksvattnet, luften, väven av liv. De som sitter vid panelerna i maskinrummet har rapporterat i decennier: det här fungerar inte. Nu säger de ifrån, allt skarpare för varje dag och varje år, ja de ropar: ställ om! Nu!",
    "Läs mer:",
    'Naturvårdsverkets rapport "Fördjupad utvärdering av miljömålen 2019".',
    'Forskning om planetens gränser vid Stockholms Universitet "Fyra av nio planetära gränser överskrids".',
    'FNs vetenskapliga klimatpanels senaste uppdatering i sin 6:e rapport (IPCC AR6/WG1, augusti 2021) "Summary for policymakers".',
    "Information om FNs klimatkonferens i Glasgow, Skottland i november 2021",
    "Det är ett stort nätverk av forskare runt om i världen som visar att vi befinner oss i en global miljö- och klimatkris, nu senaste bekräftat av FNs vetenskapliga klimatpanels senaste uppdatering i sin 6:e rapport (IPCC AR6/WG1, augusti 2021). En av dessa forskare är den svenske professorn Johan Rockström, som inledde 2020-talet med att 1 januari 2020 tala i Sveriges radios P1's vinterprat. Du kan lyssna på programmet ",
    "här.",
    "Där lyfter han fram tio saker som vi måste göra – nu i år:",
    "Varför ska just vi börja? Alla länder behöver göra detsamma. Men vi i Sverige har bland de bästa förutsättningarna att klara det, av alla länder. Om inte vi kan, vem kan då? Vi måste visa att det går. Vi kan visa att det går bra, att vi kan genomföra den här omställningen, och ändå ha mat på tallriken.",
    "Ja vi kan visa att vi lyckas med det, och att det till och med får oss att må bättre än vi gör nu. Det är inte längre bara bråttom, det är helt akut, det måste ske nu. Det här är inte partipolitik. Det spelar ingen roll vilket parti du vill rösta på, av andra skäl. Det här handlar om allas vår framtid, om våra barns levnadsvillkor, om våra barnbarns överlevnad. Det bygger på vetenskapliga fakta. Vi har tillgång till lösningarna. Det som saknas nu är den politiska viljan.",
]