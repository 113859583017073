import React,{useContext} from "react";
import {Box, Text,ResponsiveContext} from "grommet";

export const Card = ({text,number,logo}) => {
    const size = useContext(ResponsiveContext)
    let textSize = size==="small" || size ==="xsmall" ? "15px":"21px";
    const circle = () => {
        return (
<Box align="center" justify="center" height="30px" width="30px" border={{color:"white",size:"small"}} round="full">
                        <Text color="white">{number}</Text>
                    </Box>
        )
    }
    return (
        <Box direction="row" gap="small">
        <Box  elevation="medium" pad="small"   background="indexGreen" align="center">
            {logo ? logo: circle}
            </Box>
            <Box width="90%"  justify="center">
            <Text weight="bold" size={textSize} color="darkGreen">{text}</Text>
            </Box>
        </Box>
    )
}