export const textEng =[
    "We’re all passengers and crew on spaceship Earth. While this has been the case all along, the life support systems on the spaceship are currently in a critically bad condition. Freshwater, soils, climate, the very web of life. Human activities are changing Earth’s climate in unprecedented ways leading to the defining crisis of our time. The ones monitoring the dashboards in the engine room have been reporting for decades: this is not working. They are speaking up, louder and louder for every day and every year: Transform! Now!",
    "Learn more:",
    'Swedish Environmental Protection Agency - "Sweden’s environmental objectives – an introduction"',
    "Introduction and key scientific publications on the planetary boundaries framework",  
    'The UN’s Intergovernmental Panel on Climate Change 6th scientific assessment report (IPCC AR6 / WG1, August 2021) "Summary for policymakers"',
    "Information on the UN’s Climate Change Conference COP-26 in Glasgow, Scotland in November 2021",  
    "A large network of researchers around the world have shown that we’re in a global environmental and climate crisis, most recently confirmed by the UN’s Intergovernmental Panel on Climate Change in its latest, 6th scientific assessment report (IPCC AR6 / WG1, August 2021). One of these researchers is the Swedish professor Johan Rockström, who began the 2020s by holding a speech on Swedish Radio, on 1 January, 2020. You can listen to the radio show ",
    "here.",
    "In his speech he calls for ten essential steps to be taken - Now:",
    "Why should we be the ones to start? All countries need to do the same. But Sweden is one of the countries, if not the country, with the best conditions to successfully manage a societal transformation. If we can’t do it, who can? We have to show the world it’s possible. We can show that it’ll be alright, that we can carry out this transformation, and still have food on our plate. ",
    "We can show the world that we succeed, and that it even makes us feel better than we do today. There’s no time for delay and no room for excuses, it’s now or never. It doesn’t matter which party you vote for. This is about all our futures, about the living conditions of our children, about our grandchildren’s survival. This is a code red for humanity. It’s based on scientific facts. We have the solutions. What’s missing now is the political will. ",
]