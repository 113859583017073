import React from "react";
import { Box, Text, Anchor } from "grommet";
import { GiRoad, GiFactory, GiMineWagon, GiWheat, GiRiver, GiMoneyStack, GiEuropeanFlag } from 'react-icons/gi';
import { IoSync } from 'react-icons/io5'
import { GoLaw } from 'react-icons/go'
import { FaHandshake } from 'react-icons/fa'
import { cardText } from "../indexPage/infoComponent/cardText";
import { cardTextEng } from "../indexPage/infoComponent/cardTextEng";
import { textSwe } from "./textSwe";
import { textEng } from "./textEng";
import { linksSwe } from "./linksSwe";
import { linksEng } from "./linksEng";
import { TextPageLayout } from "../textPageLayout";
import { Card } from "./card"
import { useSelector } from "react-redux"

export const AboutPageComponent = () => {
    const language = useSelector(state => state.language.language);
    const cardTextArray = language === "SWE" ? cardText : cardTextEng;
    const textArray = language === "SWE" ? textSwe : textEng;
    const linkArray = language === "SWE" ? linksSwe : linksEng;
    const headingText = language === "SWE" ? "Varför?" : "Why?";
    const talkLink = language === "SWE" ? "https://sverigesradio.se/avsnitt/1417244" : "https://sverigesradio.se/avsnitt/1425542";
    const content =
        <Box gap="medium">
            <Text color="darkGreen">{textArray[0]}</Text>
            <Text color="darkGreen"> {textArray[6]} <Anchor href={talkLink}>{textArray[7]}</Anchor></Text>
            <Text color="darkGreen">{textArray[8]}</Text>
            <Card text={cardTextArray[0]} number="1" logo={<GiRoad color="#D0FF32" size="48px" />} />
            <Card text={cardTextArray[1]} number="2" logo={<GiFactory color="#D0FF32" size="48px" />} />
            <Card text={cardTextArray[2]} number="3" logo={<GiMineWagon color="#D0FF32" size="48px" />} />
            <Card text={cardTextArray[3]} number="4" logo={<GiEuropeanFlag color="#D0FF32" size="48px" />} />
            <Card text={cardTextArray[4]} number="5" logo={<GiWheat color="#D0FF32" size="48px" />} />
            <Card text={cardTextArray[5]} number="6" logo={<GiRiver color="#D0FF32" size="48px" />} />
            <Card text={cardTextArray[6]} number="7" logo={<GiMoneyStack color="#D0FF32" size="48px" />} />
            <Card text={cardTextArray[7]} number="8" logo={<IoSync color="#D0FF32" size="48px" />} />
            <Card text={cardTextArray[8]} number="9" logo={<GoLaw color="#D0FF32" size="48px" />} />
            <Card text={cardTextArray[9]} number="10" logo={<FaHandshake color="#D0FF32" size="48px" />} />
            <Text color="darkGreen">{textArray[9]}</Text>
            <Text color="darkGreen">{textArray[10]}</Text>
            <Text color="darkGreen">{textArray[1]}</Text>
            <Box gap="small">
                <Box gap="xsmall">
                    <Text color="darkGreen">{textArray[2]}</Text>
                    <Text size="small" color="darkGreen"><Anchor href={linkArray[0]}>{linkArray[0]}</Anchor></Text>
                </Box>
                <Box gap="xsmall">
                    <Text color="darkGreen">{textArray[3]}</Text>
                    <Text size="small" color="darkGreen"><Anchor href={linkArray[1]}>{linkArray[1]}</Anchor></Text>
                </Box>
                <Box gap="xsmall">
                    <Text color="darkGreen">{textArray[4]}</Text>
                    <Text size="small" color="darkGreen"><Anchor href={linkArray[2]}>{linkArray[2]}</Anchor></Text>
                </Box>
                <Box gap="xsmall">
                    <Text color="darkGreen">{textArray[5]}</Text>
                    <Text size="small" color="darkGreen"><Anchor href={linkArray[3]}>{linkArray[3]}</Anchor></Text>
                </Box>
            </Box>
            
        </Box>;
    return (

        <TextPageLayout header={headingText} content={content} />
    )
}