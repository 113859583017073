export const theme = {
    global: {
      breakpoints: {
        xsmall : {
          value: 500,
        },
        small: {
            value: 800,
        },
        medium: {
          value: 1000,
        },
        large: {
          value: 1200,
        },
        xlarge: {
          value: 1400,
        },
        xxlarge: {
        value: 2400,
      },
    },
      colors: {
        primary: '#42573D',
        brand: '#42573D',
        border: "#42573D",
        dark: "#fff", 
        light: "#444444",
        footer: "#C9E4CA",
        darkGreen: "#42573D",
        lightGreen: "#95BD8C",
        lime: "#D0FF32",
        indexGreen: "#95BD8CBF",
      },
      font: {
        family: 'Sans-serif',
        size: '18px',
        height: '20px',
      },
    },
    button: {
      primary: {
        color: '#95BD8C',
        border: "#95BD8C"
      },
      border: {
        color: "#95BD8C",
      }
    }
  };
  