import React,{useContext} from "react";
import {Box, ResponsiveContext, Text} from "grommet"

const SmallLayout = ({headerSize, header, content}) =>{
    return (
        <Box>
        <Box background="#95BD8C"  pad="medium">
         <Text textAlign="center" color="lime" weight="bold" size={headerSize} > {header}</Text>
         </Box>
        <Box pad="medium">
            {content}
        </Box>
    </Box>
    )
}

const LargeLayout = ({headerSize, header, content}) =>{
    return (
        <Box direction="row">
        <Box width="25%">
        <Box margin={{top:"26px"}} background="#95BD8C"  pad="medium">
         <Text color="lime" weight="bold" size={headerSize} textAlign="end"> {header}</Text>
        </Box>
        </Box>
        <Box width="70%" pad="medium" gap="small">
            {content}
        </Box>
    </Box>
    )
}

export const TextPageLayout = ({header,content}) =>{
    const size = useContext(ResponsiveContext)
    let headerSize = size==="small" || size ==="xsmall" ? "medium":"xlarge";
    return(
        <>
        {size==="small" || size ==="xsmall"?<SmallLayout headerSize={headerSize} header={header} content={content}/>:<LargeLayout headerSize={headerSize} header={header} content={content}/>}
</>
    )
}